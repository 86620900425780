import { host } from '@/const'
import { Entity, EntityMeta } from 'apptimizm-ui'
import { Area, AreaMeta } from '../areas/types'
import { Product, ProductMeta } from '../products/types'

const productMeta = new ProductMeta()
const areaMeta = new AreaMeta()

class ScannedProducts extends Entity {
  id: string = ''
  amount: number = 0
  zone: Area = new Area()
  product: Product = new Product()
}

class ScannedProductsEntity extends EntityMeta<ScannedProducts> {
  endpoint: string = host + '/api/v1/scanned_product'

  fields = {
    id: { type: String },
    zone: { type: Object },
    product: { type: Object },
    amount: { type: Number }
  }

  load (data: any): ScannedProducts {
    const result = super.load(data)
    if (data.zone) result.zone = areaMeta.load(data.zone)
    if (data.product) result.product = productMeta.load(data.product)
    return result
  }
}

export { ScannedProducts, ScannedProductsEntity }