import axios from '@/axios'
import { host } from '@/const'
import { getCeleryTask } from '@/core/celery'
import { store, mutations } from '@/store'
import { ButtonLoader } from '@/UI/loader'
import { DefaultInput, DefaultTableExposed, NumberInput } from 'apptimizm-ui'
import { computed, defineComponent, onMounted, Ref, ref } from 'vue'
import { ProjectMeta } from '../projects/types'
import Table from './table'

export default defineComponent({
  setup () {
    const table = ref(null) as unknown as Ref<DefaultTableExposed>
    const projectMeta = new ProjectMeta()
    const isProjectSelected = computed(() => !!store.currentProject.id)
    const isLoading = ref<{[key: string]: boolean}>({
      report: false
    })
    const tableFilter = {
      vendorCode: ref(''),
      barcode: ref(''),
      zoneNumberStart: ref(0),
      zoneNumberEnd: ref(0),
      get tableParams () {
        const res: { [code: string]: string } = {}
        if (store.currentProject.id) res.project = store.currentProject.id
        if (this.vendorCode.value) res.vendor_code = this.vendorCode.value
        if (this.barcode.value) res.barcode = this.barcode.value
        if (this.zoneNumberStart.value) res.zone_number_start = this.zoneNumberStart.value.toString()
        if (this.zoneNumberEnd.value) res.zone_number_end = this.zoneNumberEnd.value.toString()

        return res
      }
    }

    const getReport = async () => {
      if (!isProjectSelected.value) return
      isLoading.value.report = true
      const { data } = await axios.post(host + '/api/v1/reports/products-in-zones/', {
        project: store.currentProject.id
      })

      const celaryResult = (await getCeleryTask(data.task_id)).data
      if (celaryResult.result) {
        window.open(celaryResult.result)
        mutations.pushNotification(`Отчет по разделу «Товары в зонах» в проекте «${store.currentProject.name}» сформирован`)
      } else mutations.pushNotification('Ошибка формирования отчета', true)
      isLoading.value.report = false
    }

    onMounted(async () => {
      if (!isProjectSelected.value && localStorage.projectID) {
        try {
          const result = projectMeta.load((await axios.get(`${projectMeta.endpoint}${localStorage.projectID}`)).data)
          if (result?.id) mutations.setCurrentProject({ id: result.id, name: result.name, address: result.address })
        } catch (error) {
          mutations.pushNotification('Не удалось получить выбранный проект', true)
        }
      }
    })

    return () => (
      <div style="min-width: 1780px">
        <div class="page-top-panel">
          <h1>Товары в зонах</h1>
          <div class="page-top-panel-row mt-5">
            <label>
              <DefaultInput
                class={tableFilter.vendorCode.value ? 'search input-placeholder-up' : 'search'}
                placeholder="Код товара"
                onValueChange={(v :string) => { tableFilter.vendorCode.value = v }}
                modelValue={tableFilter.vendorCode.value}
              />
            </label>
            <label>
              <DefaultInput
                class={tableFilter.barcode.value ? 'search input-placeholder-up' : 'search'}
                placeholder="Штрих-код товара"
                onValueChange={(v :string) => { tableFilter.barcode.value = v }}
                modelValue={tableFilter.barcode.value}
              />
            </label>
            <label>
              <NumberInput
                class={`search ${!tableFilter.zoneNumberStart.value ? 'hide-zero' : 'input-placeholder-up'}`}
                placeholder="Зоны с..."
                modelValue={tableFilter.zoneNumberStart.value}
                onValueChange={(v: number) => { tableFilter.zoneNumberStart.value = parseInt(String(v)) } }
              />
            </label>
            <label>
              <NumberInput
                class={`search ${!tableFilter.zoneNumberEnd.value ? 'hide-zero' : 'input-placeholder-up'}`}
                placeholder="Зоны по..."
                modelValue={tableFilter.zoneNumberEnd.value}
                onValueChange={(v: number) => { tableFilter.zoneNumberEnd.value = parseInt(String(v)) } }
              />
            </label>
            <button class="default-button right-item" onClick={getReport}>
              <ButtonLoader hidden={!isLoading.value.report}/>
              Сформировать отчёт
            </button>
          </div>
        </div>
        <div class="layout">
          {isProjectSelected.value ? <Table tableFilter={tableFilter} table={table}/> : null}
        </div>
      </div>
    )
  }
})